<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <admin-etc-menu></admin-etc-menu>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-row>

                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    팝업 설정
                                    <v-btn
                                            color="primary"
                                            class="mx-2 white&#45;&#45;text center-block float-right"
                                            small
                                            @click="btnClick(1)"
                                    >
                                        추가
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" class="overflow-x-auto">
                                    <table class="table-top-s table-top-s-b" style="min-width: 700px;">
                                        <colgroup>
                                            <!--<col style="width:16%"/>-->
                                            <col style="width:10%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:15%"/>
                                            <col style="width:8%"/>
                                            <col style="width:8%"/>
                                        </colgroup>
                                        <tr>
                                            <th>번호</th>
                                            <th>팝업제목</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <th>종류</th>
                                            <th>사용</th>
                                            <th>수정</th>
                                            <th>삭제</th>
                                        </tr>
                                        <tr v-for="(item, i) in popList"
                                            :key=i
                                        >
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td @click="update(item)" style="cursor:pointer;">
                                                {{item.popTitle}}
                                            </td>
                                            <td>
                                                {{item.popStartDate}}
                                                <!--<input v-model="item.popStartDate" placeholder="mm-dd">-->
                                            </td>
                                            <td>
                                                {{item.popEndDate}}
                                                <!--<input v-model="item.popEndDate" placeholder="mm-dd">-->
                                            </td>
                                            <td>
                                                <template v-if="item.popType === 'true'">팝업창</template>
                                                <template v-else>레이어</template>

                                            </td>
                                            <td>
                                                <template v-if="item.useYn === 'Y'">사용</template>
                                                <template v-else>미사용</template>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="primary"
                                                        class="mx-4 white--text center-block float-right"
                                                        style="font-weight: bold"
                                                        x-small
                                                        @click="update(item)"
                                                >
                                                    수정
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn
                                                        color="error"
                                                        class="mx-4 white--text center-block float-right"
                                                        style="font-weight: bold"
                                                        x-small
                                                        @click="deletePop(item)"
                                                >
                                                    삭제
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </table>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminEtcMenu from '../../components/AdminEtcMenu'
    export default {
        name: 'AdminPopList',
        components: {AdminEtcMenu},
        data: () => ({
            page: 1,
            pageCount: 0,
            itemsPerPage: 30,

            popList: [],


        }),
        created: function () {
            // 맨위로
            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getList();

        },
        methods: {
            getList() {
                return this.$store.dispatch("pop/getPopList", {})
                    .then((resp) => {
                        setTimeout(() => {

                            this.popList = resp.message;

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            getCnt() {
                return this.$store.dispatch("admin/getRoomCnt")
                    .then((resp) => {
                        this.menuCnt = resp.menuCnt;
                    })
                    .catch((err) => {
                    })
            },

            btnClick(val) {

                location.href = "/admin/pop/info";

            },
            update(item) {
                location.href = "/admin/pop/info" + '?popId=' + item.popId;

            },
            deletePop(item){
                if (!confirm("팝업정보를 삭제 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {

                    let formdata = {
                        popId : item.popId
                    };

                    return this.$store.dispatch("pop/deletePop", formdata)
                        .then((resp) => {
                            setTimeout(() => {
                                //alert("정보를 삭제 했습니다.");
                                alert(resp.message);
                                //close();
                                this.getList();

                            }, 300)
                        })
                        .catch((err) => {
                        })

                }
            },
            menuClick(route, roomGubun) {
                if(roomGubun != null){
                    location.href = route;
                }else{
                    location.href = route;
                }
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },

    }
</script>

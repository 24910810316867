<template>
    <div>
        <div class="sideMenu-title mb-2">
            기타관리
        </div>
        <div class="sideMenu">
            <!--<ul style="width:100%;" class="default-Btn-1 my-12 hidden-sm-and-down">-->
            <div class="sideMenu-group">
                <ul style="width:100%;" class="">
                    <li class="">
                        <a :class="{active : menuActive.isActive0}" @click="menuClick('/admin/pop/list', null)">팝업관리</a>
                    </li>
                </ul>
            </div>
            <v-divider class="mx-5 my-3"></v-divider>
            <div class="sideMenu-group">

                <ul style="width:100%;" class="">
                    <li class="">
                        <a :class="{active : menuActive.isActive1}" @click="menuClick('/admin/default?comnGubun=textGreet')">인사말</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive2}" @click="menuClick('/admin/default?comnGubun=textBasics')">기본사항</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive3}" @click="menuClick('/admin/default?comnGubun=textNotice')">유의사항</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive4}" @click="menuClick('/admin/default?comnGubun=textRefund')">환불규정</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive5}" @click="menuClick('/admin/default?comnGubun=textRoom')">팬션특장점</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive6}" @click="menuClick('/admin/default?comnGubun=textCamp')">캠핑특장점</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive7}" @click="menuClick('/admin/default?comnGubun=textCampDiff')">캠핑차별화</a>
                    </li>
                </ul>
            </div>
            <v-divider class="mx-5 my-3"></v-divider>
            <div class="sideMenu-group">

                <ul style="width:100%;" class="">
                    <li class="">
                        <a :class="{active : menuActive.isActive8}" @click="menuClick('/admin/imgInfo?imgGubun=PO')">수영장사진</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive9}" @click="menuClick('/admin/imgInfo?imgGubun=VA')">계곡사진</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive13}" @click="menuClick('/admin/imgInfo?imgGubun=BE')">평상사진</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive10}" @click="menuClick('/admin/imgInfo?imgGubun=ABO')">둘러보기사진</a>
                    </li>
                    <!--<li class="">
                        <a @click="menuClick('/admin/imgInfo2')">상단사진</a>
                    </li>-->
                    <li class="">
                        <a :class="{active : menuActive.isActive11}" @click="menuClick('/admin/imgInfo3?imgGubun=BAT')">배치도</a>
                    </li>
                    <li class="">
                        <a :class="{active : menuActive.isActive12}" @click="menuClick('/admin/imgInfo3?imgGubun=WTC')">오시는길</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'AdminEtcMenu',
        data: () => ({
            menuActive: {
                isActive0: false,
                isActive1: false,
                isActive2: false,
                isActive3: false,
                isActive4: false,
                isActive5: false,
                isActive6: false,
                isActive7: false,
                isActive8: false,
                isActive9: false,
                isActive10: false,
                isActive11: false,
                isActive12: false,
                isActive13: false, // 평상사진
            }
        }),
        created: function () {
            this.setMenuActive();
        },
        computed: {

        },
        methods: {
            menuClick(route) {

                location.href = route;
            },
            setMenuActive(){
                switch (window.location.pathname) {
                    case "/admin/pop/list":{
                        this.menuActive.isActive0 = true;
                        break;
                    }
                    case "/admin/default":{
                        let comnGubun = this.$getParameterByName('comnGubun');
                        switch (comnGubun) {
                            case "textGreet": {
                                this.menuActive.isActive1 = true;
                                break;
                            }
                            case "textBasics": {
                                this.menuActive.isActive2 = true;
                                break;
                            }
                            case "textNotice": {
                                this.menuActive.isActive3 = true;
                                break;
                            }
                            case "textRefund": {
                                this.menuActive.isActive4 = true;
                                break;
                            }
                            case "textRoom": {
                                this.menuActive.isActive5 = true;
                                break;
                            }
                            case "textCamp": {
                                this.menuActive.isActive6 = true;
                                break;
                            }
                            case "textCampDiff": {
                                this.menuActive.isActive7 = true;
                                break;
                            }
                        }
                        break;
                    }
                    case "/admin/imgInfo":{
                        let imgGubun = this.$getParameterByName('imgGubun');
                        switch (imgGubun) {
                            case "PO": {
                                this.menuActive.isActive8 = true;
                                break;
                            }
                            case "VA": {
                                this.menuActive.isActive9 = true;
                                break;
                            }
                            case "BE": {
                                this.menuActive.isActive13 = true;
                                break;
                            }
                            case "ABO": {
                                this.menuActive.isActive10 = true;
                                break;
                            }
                        }
                        break;
                    }
                    case "/admin/imgInfo3":{
                        let imgGubun = this.$getParameterByName('imgGubun');
                        switch (imgGubun) {
                            case "BAT": {
                                this.menuActive.isActive11 = true;
                                break;
                            }
                            case "WTC": {
                                this.menuActive.isActive12 = true;
                                break;
                            }
                        }
                        break;
                    }
                }

            }

        },

    }

</script>

<style lang="scss" scoped>
    /*@import './base.scss';*/


</style>
